

import { React } from 'react';
import '../../assets/styles/forms/NewHistory.css'
import NewHistory from '../../components/forms/NewHistory';


export default function CreateStoryPage() {

    return (

        <NewHistory />

    )
}