import React from 'react';

import '../../assets/styles/other/Spinner.css'

const Spinner = () => {
  return (
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
